//Sissala Consulta Pública (Horário das Aulas)
export const linkSissala = "https://www.sissala.vr.uff.br/reserva";

//Horário do BusUFF
export const linkBusUff =
  "https://atendimento.vr.uff.br/front/helpdesk.faq.php?id=67";

//FAQ do GLPI
export const linkGlpiFaq =
  "https://atendimento.vr.uff.br/front/helpdesk.faq.php";

//BAVR - Biblioteca do Aterrado - Volta Redonda
export const linkBavr = "http://bibliotecas.uff.br/bavr/";

//idUFF
export const linkIdUff = "https://app.uff.br/iduff/";

//Sei
export const linkSei = "https://www.uff.br/sei";

//Site UFF
export const linkUff = "https://www.uff.br/";

//Site ICEx
export const linkIcex = "http://icex.sites.uff.br/";

//Site ICHS
export const linkIchs = "http://ichs.sites.uff.br/";

//Facebook ICEx
export const linkFacebookIcex = "https://facebook.com/icexvr/";

//Facebook ICHS
export const linkFacebookIchs = "https://facebook.com/ICHSVR/";

//Instagram ICEx
export const linkInstagramIcex =
  "https://instagram.com/icexoficial?igshid=YmMyMTA2M2Y=";

//Instagram ICHS
export const linkInstagramIchs =
  "https://instagram.com/ichsoficial?igshid=YmMyMTA2M2Y=";

//Siscoord ICEx
export const linkSiscoordIcex =
  "https://www.siscoord.vr.uff.br/formulario1_icex.php";

//Siscoord Ichs
export const linkSiscoordIchs =
  "https://www.siscoord.vr.uff.br/formulario1_ichs.php";

//Wifi - Visitantes
export const linkWifiVisitantes =
  "https://www.atendimento.vr.uff.br/front/helpdesk.faq.php?id=50";

//Wifi Eduroam - Android
export const linkWifiAndroid =
  "https://www.atendimento.vr.uff.br/front/helpdesk.faq.php?id=50";

//Wifi Eduroam - Apple
export const linkWifiApple =
  "https://www.atendimento.vr.uff.br/front/helpdesk.faq.php?id=51";

//Wifi Eduroam - Linux (Ubuntu)
export const linkWifiLinux =
  "https://www.atendimento.vr.uff.br/front/helpdesk.faq.php?id=49";

//Wifi Eduroam - Windows
export const linkWifiWindows =
  "https://www.atendimento.vr.uff.br/front/helpdesk.faq.php?id=48";

//Carteirinha digital - Google play
export const linkCarteirinhaGoogle =
  "https://play.google.com/store/apps/details?id=br.uff.carteirinha_uff_digital";

//Carteirinha digital - Apple store
export const linkCarteirinhaApple =
  "https://apps.apple.com/br/app/carteirinha-digital-uff/id1536612106";
